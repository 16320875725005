import React from 'react';
import { UseQueryResult } from '@tanstack/react-query'
import * as APIt from '../../../API';
import ManageVisitorRequestsTablePanel from './TablePanel';
import { useBundle } from '@amzn/react-arb-tools';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { VisitorTypes } from 'src/constants/Constants';
import { debug } from '../../../utils/commonUtils';

interface IManageVisitorsProps {
  admin: boolean;
  airSite: boolean;
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  manageVisitorRequestsQuery: UseQueryResult<APIt.VisitorRequest[] | null, unknown>;
  minDateFilter: string | null;
  maxDateFilter: string | null;
  pageSize: number;
  setCardholdersQuickLinksCallback: Function;
  setMinDateCallback: Function;
  setMaxDateCallback: Function;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  setVisitorTypeCallback: Function;
  setVisitorRequestCallback: Function;
  siteCode: string;
  statusesFilter: { label : string, value: string }[] | undefined;
  userCanManageVisitorsForSite: boolean;
  username: string;
}

export default function ManageVisitors(props: IManageVisitorsProps) {
  debug(`ManageVisitors() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.index');

  if (isBundleLoading) return <Spinner/>;

  if (!props.admin && !props.userCanManageVisitorsForSite)
    return(
      <Alert
        statusIconAriaLabel={'missing-permision'}
        type='info'
        header={
          props.siteCode
            ? bundle.getMessage('missing-permission')
            : ''
        }
      >
        {
          props.siteCode
            ? `${bundle.getMessage('missing-permission-statement')} ${props.siteCode}.`
            : bundle.getMessage('select-a-site')
        }
      </Alert>
    );

  return(
    <ManageVisitorRequestsTablePanel
      airSite={props.airSite}
      cardholdersQuickLinks={props.cardholdersQuickLinks}
      darkMode={props.darkMode}
      manageVisitorRequests={
        props.manageVisitorRequestsQuery.data?.filter(vr => props.airSite ? true : vr.visitor_type !== VisitorTypes.Employee) || []}
      minDateFilter={props.minDateFilter}
      maxDateFilter={props.maxDateFilter}
      pageSize={props.pageSize}
      refreshCallback={props.manageVisitorRequestsQuery.refetch}
      setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
      setMaxDateCallback={props.setMaxDateCallback}
      setMinDateCallback={props.setMinDateCallback}
      setPageSizePrefCallback={props.setPageSizePrefCallback}
      setSplitPanelCallback={props.setSplitPanelCallback}
      setSplitPanelOpenCallback={props.setSplitPanelOpenCallback}
      setStatusFilterCallback={props.setStatusFilterCallback}
      setVisitorTypeCallback={props.setVisitorTypeCallback}
      setVisitorRequestCallback={props.setVisitorRequestCallback}
      siteCode={props.siteCode}
      statusesFilter={props.statusesFilter}
      username={props.username}
    />
  );
}


