import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  ExpandableSection,
  Flashbar,
  Form,
  FormField,
  Grid,
  Input,
  ProgressBar,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import * as APIt from '../../../API';
import VisitorAssetsTablePanel from './Assets/TablePanel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getLookupTypeValueId,
  queryEmployeeDetails,
  queryVisitorAccessLevelsForVisitor,
  queryVisitorAssets,
  queryVisitorAssetTypes
} from 'src/components/utils';
import { CheckInSteps, LookupTypes, VisitorRequestStatus, VisitorTypes } from 'src/constants/Constants';
import {
  checkInVisitor,
  checkOutVisitor,
  handleNonWelcomeVisitor,
  queryBadgeAssignment,
  queryRequestEscorts,
  queryVisitorRequestForVendorDayPassBadgeNum,
  subscribeOnVisitorUpdate
} from './utils';
import VisitorAccessLevelRequestsTablePanel from './AccessLevels/TablePanel';
import * as uuid from 'uuid';
import EscortsTablePanel, { HeaderMessages } from 'src/components/common/Escorts/TablePanel';
import { IEscort } from 'src/types';
import { debug, validateEV3Input } from 'src/utils/commonUtils';

export interface ICheckInProps{
  airSite: boolean;
  cancelCheckInCallback: Function;
  checkInCallback: Function;
  darkMode: boolean;
  requestAllActivatedCallback: Function;
  siteCode: string;
  submissionProcessing: boolean;
  username: string;
  visitorRequest: APIt.VisitorRequest;
}

export default function CheckIn(props: ICheckInProps) {
  debug(`CheckIn() props is ${JSON.stringify(props)}`);

  const [accessLevelsErrorText, setAccessLevelsErrorText] = useState<string>('');
  const [assetsErrorText, setAssetsErrorText] = useState<string>('');
  const [badge, setBadge] = useState<string>('');
  const [checkInProcessing, setCheckInProcessing] = useState<boolean>(false);
  const [checkInBadgeValidating, setCheckInBadgeValidating] = useState<boolean>(false);
  const [checkInStarted, setCheckInStarted] = useState<boolean>(false);
  const [checkInStep, setCheckInStep] = useState<string | null>(null);
  const [checkInProgress, setCheckInProgress] = useState<number>(0);
  const [checkOutProcessing, setCheckOutProcessing] = useState<boolean>(false);
  const [checkOutStep, setCheckOutStep] = useState<string | null>(null);
  const [checkOutProgress, setCheckOutProgress] = useState<number>(0);
  const [idVerified, setIdVerified] = useState<boolean>(false);
  const [escorts, setEscorts] = useState<IEscort[]>([]);
  const [escortsChanged, setEscortsChanged] = useState<boolean>(false);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean | null>(null);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean | null>(null);
  const [existingBadgeAssignment, setExistingBadgeAssignment] = useState<APIt.BadgeAssignment>();
  const [existingBadgeAssignmentWelcomeVisitorRequest, setExistingBadgeAssignmentWelcomeVisitorRequest] = useState<APIt.VisitorRequest>();
  const [errorText, setErrorText] = useState<string>('');
  const [escortsErrorText, setEscortsErrorText] = useState<string>('');
  const [escortsTablePanelTouched, setEscortsTablePanelTouched] = useState<boolean>(false);
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [expandEscorts, setExpandEscorts] = useState<boolean>(false);
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);
  const [showStillPendingAccessLevelsFlashbar, setShowStillPendingAccessLevelsFlashbar] = useState(false);
  const [vendorDayPassBadgeNum, setVendorDayPassBadgeNum] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.CheckIn');

  const queryClient = useQueryClient();

  const visitorAssetsQuery = useQuery({
    onError: (error: Error) => setAssetsErrorText(error.message),
    onSuccess: () => setAssetsErrorText(''),
    queryKey: ['manageVisitorCheckInAssets'],
    queryFn: async () => {
      const siteSourceSystemId = await getLookupTypeValueId(LookupTypes.SiteSourceSystem, 'PACS');
      return await queryVisitorAssets(props.visitorRequest.visitor_id, props.siteCode, siteSourceSystemId);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const visitorAccessLevelsQuery = useQuery({
    onError: (error: Error) => setAccessLevelsErrorText(error.message),
    onSuccess: () => setAccessLevelsErrorText(''),
    queryKey: ['manageVisitorCheckInVisitorAccessLevels'],
    queryFn: async () => {
      return await queryVisitorAccessLevelsForVisitor(props.visitorRequest.visitor_id);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const requestEscortsQuery = useQuery({
    onError: (error: Error) => setEscortsErrorText(error.message),
    onSuccess: (data) => {
      setEscortsErrorText('');
      setEscorts(data);
    },
    queryKey: ['requestEscortsForCheckIn'],
    queryFn: async () => {
      if (props.visitorRequest.request_id) {
        const escorts = await queryRequestEscorts(props.visitorRequest.request_id);
        const escortsWithDetails: IEscort[] = [];
        for (let escort of escorts) {
          const employeeDetails = await queryEmployeeDetails(escort.escort_id);
          if (employeeDetails) {
            const expandedEscort: IEscort = {
              __typename: 'EmployeeDetails',
              status: employeeDetails.siteCode,
              firstName: employeeDetails.firstName,
              id: employeeDetails.id,
              idHash: employeeDetails.idHash,
              lastName: employeeDetails.lastName,
              siteCode: employeeDetails.siteCode,
              title: employeeDetails.title,
              username: employeeDetails.username,
            };
            escortsWithDetails.push(expandedEscort);
          }
        } 
        return escortsWithDetails;
      }
      return([]);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['manageVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  const checkInVisitorMutation = useMutation({
    mutationFn: () =>
      checkInVisitor(
        {
          ...props.visitorRequest,
          badge_id: badge,
          vendor_day_pass_badge_num: vendorDayPassBadgeNum,
        },
        escortsChanged ? escorts : [],
        props.username,
        setCheckInStep,
        setCheckInProgress),
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: async () => {
      setErrorText('');
      setAssetsErrorText('');
      setAccessLevelsErrorText('');
      setEscortsErrorText('');
      setVendorDayPassBadgeNum('');
      setBadge('');
      if (queryClient.getQueryState(['requestEscorts']) !== undefined) {
        await queryClient.fetchQuery({ queryKey: ['requestEscorts'] });
      }
      if (queryClient.getQueryState(['visitorActions']) !== undefined) {
        await queryClient.fetchQuery({ queryKey: ['visitorActions'] });
      }
      props.checkInCallback();
    },
  });

  const checkIn = async () => {
    debug(`checkIn() props.visitorRequest is ${JSON.stringify(props.visitorRequest)}`);
    setCheckInStarted(true);
    checkInVisitorMutation.mutate();
    setCheckInStarted(false);
  };

  const handleCheckInButton = async () => {
    setCheckInStep(CheckInSteps.Initializing);
    setCheckInProcessing(true);
    setErrorText('');
    setShowContinueButton(false);
    debug(`handleCheckInButton() props.visitorRequest is ${JSON.stringify(props.visitorRequest)}`);
    if (props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor && vendorDayPassBadgeNum) {
      try {
        let existingBadgeAssignment = await queryBadgeAssignment(parseInt(vendorDayPassBadgeNum));
        setExistingBadgeAssignment(existingBadgeAssignment);
        debug(`handleCheckInButton() existingBadgeAssignment is ${JSON.stringify(existingBadgeAssignment)}`);
        let existingBadgeAssignmentInWelcome = await queryVisitorRequestForVendorDayPassBadgeNum(
          vendorDayPassBadgeNum,
          VisitorRequestStatus.CheckedIn);
        setExistingBadgeAssignmentWelcomeVisitorRequest(existingBadgeAssignmentInWelcome);
        debug(`handleCheckInButton() existingBadgeAssignmentInWelcome is ${JSON.stringify(existingBadgeAssignmentInWelcome)}`);
        if (!existingBadgeAssignment && existingBadgeAssignmentInWelcome) {
          existingBadgeAssignment = 
            {
              __typename: 'BadgeAssignment',
              activate: '',
              badge_id: vendorDayPassBadgeNum,
              badge_status_name: '',
              badge_type_name: '',
              deactivate: '',
              email: '',
              empid: 0,
              employee_id: 0,
              firstname: existingBadgeAssignmentInWelcome.first_name,
              lastname: existingBadgeAssignmentInWelcome.last_name,
              status: 0,
              type: 0,
            };
        }
        debug(`handleCheckInButton() existingBadgeAssignment is ${JSON.stringify(existingBadgeAssignment)}`);
        if (existingBadgeAssignment) {
          if (existingBadgeAssignment.employee_id) {
            setErrorText(bundle.formatMessage('badge-already-assigned-to-employee',
              {
                badgeNum: vendorDayPassBadgeNum,
                cardholderFirstAndLastName: `${existingBadgeAssignment.firstname} ${existingBadgeAssignment.lastname}`,
                employeeId: existingBadgeAssignment.employee_id,
              }));
            if (!existingBadgeAssignment.employee_id) {
              setShowContinueButton(true);
            }
          } else {
            setErrorText(bundle.formatMessage('badge-already-assigned-to-vendor',
              {
                badgeNum: vendorDayPassBadgeNum,
                cardholderFirstAndLastName: `${existingBadgeAssignment.firstname} ${existingBadgeAssignment.lastname}`,
              }));
            if (!existingBadgeAssignment.employee_id) {
              setShowContinueButton(true);
            }
          }
        } else {
          await checkIn();
        }
      } catch(error) {
        console.error(`handleCheckInButton() error is ${JSON.stringify(error)}`);
        setErrorText(((error as any).errors[0]?.message as string) || `An error has occurrred. ${error} ${JSON.stringify(error)}`);
      }
    } else {
      await checkIn();
    }
    setCheckInProcessing(false);
  };

  const checkOutVisitorMutation = useMutation({
    mutationFn: async () => {
      debug(`CheckIn() checkoutVisitorMutation() existingBadgeAssignment is ${JSON.stringify(existingBadgeAssignment)}`);
      debug(`CheckIn() checkoutVisitorMutation() existingBadgeAssignmentWelcomeVisitorRequest is ${JSON.stringify(existingBadgeAssignmentWelcomeVisitorRequest)}`);
      if (existingBadgeAssignmentWelcomeVisitorRequest) {
        await checkOutVisitor(existingBadgeAssignmentWelcomeVisitorRequest, false, props.username, setCheckOutStep, setCheckOutProgress);
      } else if (existingBadgeAssignment) {
        const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
        const personId = existingBadgeAssignment.email;
        const visitorTypeId = existingBadgeAssignment.employee_id
          ? await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Employee)
          : await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.UnescortedVendor);
        const createVisitorInput: APIt.CreateVisitorInput = {
          created_by: props.username,
          first_name: existingBadgeAssignment.firstname,
          last_name: existingBadgeAssignment.lastname,
          id: uuid.v4(),
          person_id: personId,
          person_source_system_id: personSourceSystemId,
          visitor_type_id: visitorTypeId!,
        };
        const createdVisitor = await handleNonWelcomeVisitor(
          createVisitorInput,
          existingBadgeAssignment.empid,
          setCheckOutStep,
          setCheckOutProgress);
        const visitorRequest: APIt.VisitorRequest = {
          __typename: 'VisitorRequest',
          all_activated: false,
          approvals_needed: '',
          created: '',
          created_by: '',
          site_id: '',
          status: '',
          updated: '',
          updated_by: '',
          vendor_day_pass_badge_num: existingBadgeAssignment.badge_id,
          visitor_id: createdVisitor.id,
          visitor_type: '',
          visitor_type_id: '',
        };
        await checkOutVisitor(visitorRequest, false, props.username, setCheckOutStep, setCheckOutProgress);
      }
    },
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: () => {
      setErrorText('');
      setExistingBadgeAssignment(undefined);
      setExistingBadgeAssignmentWelcomeVisitorRequest(undefined);
      setShowContinueButton(false);
    },
  });

  const handleContinueButton = async () => {
    console.log(`handleContinueButton()`);
    setCheckOutProcessing(true);
    await checkOutVisitorMutation.mutateAsync();
    await checkIn();
    setCheckOutProcessing(false);
  };

  const isSubmitDisabled = (props: ICheckInProps, vendorDayPassBadgeNum: string, escortMissingSiteAccess: boolean | null, escortMissingActiveBadge: boolean | null, escorts: IEscort[], escortsTablePanelTouched: boolean, isIdVerified: boolean): boolean | undefined => {
    const submitDisabled = (
      props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
      && (vendorDayPassBadgeNum == '' || !isIdVerified)
    )
      ||
      (
        (props.visitorRequest.visitor_type == VisitorTypes.Visitor || props.visitorRequest.visitor_type == VisitorTypes.Employee)
        &&
        ((
          escortMissingSiteAccess == null
          || escortMissingSiteAccess
          || escortMissingActiveBadge == null
          || escortMissingActiveBadge
          || escorts.length < 1
        )
          || escortsTablePanelTouched)
      );
    console.log(`isSubmitDisabled() submitDisabled ${submitDisabled}`);
    return submitDisabled;
  }

  const validateVendorBadgeInput = (data: string) => {
    let badgeVal = data;

    // Check for EV3 badge input
    const ev3BadgeID = validateEV3Input(badgeVal);

    // Set EV3 badge ID, if any
    if (ev3BadgeID) {
      badgeVal = ev3BadgeID;
      setVendorDayPassBadgeNum(ev3BadgeID);
    }

    // Validate input normally
    const badgeInt = parseInt(badgeVal);
    setVendorDayPassBadgeNum(isNaN(badgeInt) ? '' : badgeInt.toString());
    
    // Finished input validation
    setCheckInBadgeValidating(false);
  }

  const setVisitorRequestStatus = (request_id: string, visitor_id: string) => {
    props.requestAllActivatedCallback(request_id, visitor_id);
  };
  
  useEffect(() => {
    visitorAssetsQuery.refetch();
    visitorAccessLevelsQuery.refetch();
  },[props.visitorRequest, props.siteCode]);

  useEffect(() => {
    if (checkInStep == CheckInSteps.AssigningAccessLevels) {
      visitorAccessLevelsQuery.refetch();
    }
  } 
  , [checkInStep, checkInProgress])

  // Due to input delay with EV3 badge readers, set a timer before validating input
  useEffect(() => {
    const badgeCheckTimeout = setTimeout(() => validateVendorBadgeInput(vendorDayPassBadgeNum), 500);
    return () => clearTimeout(badgeCheckTimeout);
  }, [vendorDayPassBadgeNum]);

  useEffect(() => {
    const pendingAccessLevels = visitorAccessLevelsQuery.data?.filter((accessLevel) => accessLevel.status === 'Pending Approval') || [];
    setPendingApprovalCount(pendingAccessLevels.length);
    setShowStillPendingAccessLevelsFlashbar(pendingAccessLevels.length > 0);
  }, [visitorAccessLevelsQuery.data]);

  useEffect(() => {
    const subscribe = async () => {
      if (checkInStarted) {
        const subscription = await subscribeOnVisitorUpdate(props.visitorRequest, setVisitorRequestStatus);
        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        };
      }
    }
    subscribe();
  }, [checkInStarted]);
  
  if (isBundleLoading) return <Spinner/>;

  return(
    <form onSubmit={e => {
      e.preventDefault();
      if (!isSubmitDisabled(props, vendorDayPassBadgeNum, escortMissingSiteAccess, escortMissingActiveBadge, escorts, escortsTablePanelTouched, idVerified)) handleCheckInButton();
    }}>
    <Form
      actions={
        <SpaceBetween direction='horizontal' size='xs'>
          <Button
            disabled={checkInVisitorMutation.isLoading || checkInProcessing}
            formAction='none'
            variant='normal'
            onClick={() =>
              {
                setBadge('');
                setCheckOutProcessing(false);
                setExistingBadgeAssignment(undefined);
                setExistingBadgeAssignmentWelcomeVisitorRequest(undefined);
                setErrorText('');
                setShowContinueButton(false);
                setVendorDayPassBadgeNum('');
                props.cancelCheckInCallback();
              }
            }
          >
            {bundle.getMessage('cancel')}
          </Button>
          {!showContinueButton && !existingBadgeAssignment
          &&
          <Button
            disabled={
              isSubmitDisabled(props, vendorDayPassBadgeNum, escortMissingSiteAccess, escortMissingActiveBadge, escorts, escortsTablePanelTouched, idVerified)
            }
            formAction={isSubmitDisabled(props, vendorDayPassBadgeNum, escortMissingSiteAccess, escortMissingActiveBadge, escorts, escortsTablePanelTouched, idVerified) ? 'none' : 'submit'}
            variant='primary'
            loading={
              checkInVisitorMutation.isLoading
              || checkInProcessing
              || checkInBadgeValidating
            }
          >
            {bundle.getMessage('check-in')}
          </Button>
          }
          {showContinueButton && existingBadgeAssignment
          &&
          <Button
            variant='primary'
            loading={checkOutVisitorMutation.isLoading || checkOutProcessing}
            onClick={handleContinueButton}
          >
            {bundle.getMessage('continue')}
          </Button>
          }
        </SpaceBetween>
      }
      errorText={errorText}
      header={
        <>
          {showStillPendingAccessLevelsFlashbar &&(
            <Flashbar
              items={[
                {
                  type: 'warning',
                  content: bundle.getMessage('still-pending-access-levels1') + ` ${pendingApprovalCount} ` + bundle.getMessage('still-pending-access-levels2'),
                  dismissible: true,
                  onDismiss: () => setShowStillPendingAccessLevelsFlashbar(false),
                }
              ]}
            />
          )}
          {props.visitorRequest.first_name} {props.visitorRequest.last_name}
          {(props.visitorRequest.company != undefined) ? ` (${props.visitorRequest.company})` : ''}
        </>
      }
    >
      <SpaceBetween direction='vertical' size={'s'}>
        {
          (
            props.visitorRequest.visitor_type === VisitorTypes.Visitor || props.visitorRequest.visitor_type === VisitorTypes.EscortedWorker
            ||
            (props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee)
          )
          &&
          <FormField label={bundle.getMessage('badge-identifier')} >
            <Input
              autoFocus={true}
              onChange={event => {
                const badgeInt = parseInt(event.detail.value);
                setBadge(isNaN(badgeInt) ? '' : badgeInt.toString());
              }}
              invalid={false}
              value={badge}
            />
          </FormField>
        }
        {
            props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
            &&
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }, { colspan: 2 }]}>
              <div>
                <FormField
                  label={bundle.getMessage('vendor-day-pass-badge-number')}
                >
                  <Input
                    autoFocus={true}
                    disabled={checkInVisitorMutation.isLoading || checkInProcessing}
                    invalid={false}
                    onChange={({ detail }) => {
                      setErrorText('');
                      setExistingBadgeAssignment(undefined);
                      setExistingBadgeAssignmentWelcomeVisitorRequest(undefined);
                      setShowContinueButton(false);
                      setVendorDayPassBadgeNum(detail.value);
                      setCheckInBadgeValidating(true);
                    }}
                    value={vendorDayPassBadgeNum}
                  />
                </FormField>
              </div>
              <div>
                <FormField label={bundle.getMessage('id-verified')}>
                  <Checkbox
                    checked={idVerified}
                    onChange={({ detail }) => setIdVerified(detail.checked)}
                  />
                </FormField>
              </div>
            </Grid>
        }
        {
          (props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
          || props.visitorRequest.visitor_type == VisitorTypes.Employee)
          &&
          <VisitorAccessLevelRequestsTablePanel
            airSite={props.airSite}
            closeCallback={undefined}
            refreshCallback={visitorAccessLevelsQuery.refetch}
            readOnly={true}
            showAddAccessLevelCallback={undefined}
            vendorDayPassBadgeNum={null}
            visitorAccessLevelsQuery={visitorAccessLevelsQuery}
            visitorRequest={props.visitorRequest}
          />
        }
        <ExpandableSection
          expanded={expandAssets}
          header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${visitorAssetsQuery.data?.length})` }
          onChange={() => setExpandAssets(!expandAssets)}
        >
          <VisitorAssetsTablePanel
           isTableLoading={visitorAssetsQuery.isFetching || visitorAssetsQuery.isLoading}
           readOnly={false}
           siteCode={props.siteCode}
           username={props.username}
           visitorAssetsQuery={visitorAssetsQuery}
           visitorAssetTypesQuery={visitorAssetTypesQuery}
           visitorId={props.visitorRequest.visitor_id}
         />
        </ExpandableSection>
        {
          (props.visitorRequest.visitor_type == VisitorTypes.Visitor || props.visitorRequest.visitor_type == VisitorTypes.Employee)
          &&
          <ExpandableSection
            expanded={expandEscorts}
            header={expandEscorts ? bundle.getMessage('hide-escorts') : `${bundle.getMessage('show-escorts')} (${requestEscortsQuery.data?.length})` }
            onChange={() => setExpandEscorts(!expandEscorts)}
          >
          <EscortsTablePanel
            autoFocus={false}
            cardholdersQuickLinks={[]}
            checkForMissingSiteAccess={true}
            darkMode={props.darkMode}
            escorts={escorts}
            setEscortsTablePanelTouched={setEscortsTablePanelTouched}
            username={props.username}
            setCardholdersQuickLinksCallback={null}
            setEscortsCallback={(escorts: IEscort[]) => {
              setEscortsChanged(true);
              setEscorts(escorts);
            }}
            setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
            setEscortMissingSiteAccessCallback={setEscortMissingSiteAccess}
            siteCode={props.siteCode}
            headerMessage={HeaderMessages.ESCORTS}
          />
          </ExpandableSection>
        }
        </SpaceBetween>
    </Form>
    {checkInStep && !checkOutStep
    &&
    <ProgressBar
      value={checkInProgress}
      additionalInfo={bundle.getMessage(checkInStep)}
      description={bundle.getMessage('check-in-progress')}
      label={bundle.getMessage('progress')}
    />
    }
    {checkOutStep
    &&
    <ProgressBar
      value={checkOutProgress}
      additionalInfo={bundle.getMessage(checkOutStep)}
      description={bundle.getMessage('check-out-progress')}
      label={bundle.getMessage('progress')}
    />
    }
    </form>
  );
}