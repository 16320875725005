import React, { useEffect, useRef, useState } from 'react';
import { ApprovalStatus, AssetApprovalStatus, LookupTypes, Modes, URLS, VisitorRequestStatus, VisitorTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import Vendors from './Vendors/TablePanel';
import Escorts, { HeaderMessages } from 'src/components/common/Escorts/TablePanel';
import { IEscort } from 'src/types';
import AccessDetails, { IVendorAccessDetailsRecord } from './AccessDetails/TablePanel';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  Textarea } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { ISubmitAccessLevelRequest, submitAccessRequest } from 'src/components/SelfService/utils';
import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { getLookupTypeValueId, queryLookupTypeValueForTypeAndDescription } from 'src/components/utils';
import { useQueryClient } from '@tanstack/react-query';
import { TVendor } from 'src/types';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { debug } from 'src/utils/commonUtils';

interface IRequestVendorAccessProps {
  accessLevels: IVendorAccessDetailsRecord[];
  disableAutoApproval: boolean;
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  escorts: IEscort[];
  vendors: TVendor[];
  mode: Modes;
  reasonForAccessRequest: string;
  setAccessLevelsCallback: Function;
  setCardholdersQuickLinksCallback: Function;
  setEscortsCallback: Function;
  setReasonForAccessRequestCallback: Function;
  setVendorsCallback: Function;
  username: string;
  visitorId: string | undefined;
}

export default function RequestVendorAccess(props: IRequestVendorAccessProps) {
  debug(`RequestVendorAccess() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.index');

  const [accessLevels, setAccessLevels] = useState<IVendorAccessDetailsRecord[]>(props.accessLevels);
  const [clearDetails, setClearDetails] = useState<boolean>(false);
  const [configuredDate, setConfiguredDate] = useState<string>('December 31, 2025');
  const [isVendorDayPassEnabled, setIsVendorDayPassEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [escorts, setEscorts] = useState<IEscort[]>(props.escorts);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean | null>(null);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean>(false);
  const [reasonForAccessRequestInput, setReasonForAccessRequestInput] = useState<string>(props.reasonForAccessRequest);
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [showRequestSubmittedFlashbar, setShowRequestSubmittedFlashbar] = useState<boolean>(false);
  const [showRequestSubmissionFailure, setShowRequestSubmissionFailure] = useState<boolean>(false);
  const [showSubmitRequestAcknowledgementModal, setShowSubmitRequestAcknowledgementModal] = useState<boolean>(false);
  const [vendors, setVendors] = useState<TVendor[]>(props.vendors);

  const reasonForAccessRequestRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();

  const submitVendorAccessRequest = async () => {
    debug(`RequestVendorAccess() submitVendorAccessRequest()`);

    setRequestProcessing(true);

    const sourceSystem = 'PACS';
    const lookupPromises = [];
    let
      accessLevelSourceSystemId: string | undefined,
      assetApprovalStatusApproved: string | undefined,
      visitorTypeVendor: string | undefined,
      requestorSourceSystemId: string | undefined,
      personSourceSystemId: string | undefined,
      siteSourceSystemId: string | undefined,
      visitorStatusPendingApprovalId: string | undefined,
      visitorAccessLevelStatusPendingApprovalId: string | undefined;

    lookupPromises.push(getLookupTypeValueId(LookupTypes.VisitorType,VisitorTypes.UnescortedVendor)
      .then(id => visitorTypeVendor = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.AccessLevelSourceSystem,sourceSystem)
      .then(id => accessLevelSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.RequestorSourceSystem,sourceSystem)
      .then(id => requestorSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.PersonSourceSystem,sourceSystem)
      .then(id => personSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.PendingApproval)
      .then(id => visitorStatusPendingApprovalId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus,ApprovalStatus.PendingApproval)
      .then(id => visitorAccessLevelStatusPendingApprovalId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.AssetApprovalStatus, AssetApprovalStatus.Approved)
      .then(id => assetApprovalStatusApproved = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.SiteSourceSystem, sourceSystem)
      .then(id => siteSourceSystemId = id));

    await Promise.all(lookupPromises);

    if (!visitorTypeVendor) throw `unable to locate visitor type id for ${VisitorTypes.UnescortedVendor}`;
    if (!accessLevelSourceSystemId) throw `unable to locate access level source system id for ${sourceSystem}`;
    if (!requestorSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    if (!visitorStatusPendingApprovalId) throw `unable to locate visitor request status id for ${VisitorRequestStatus.PendingApproval}`;
    if (!visitorAccessLevelStatusPendingApprovalId) throw `unable to locate visitor access level status id for ${ApprovalStatus.PendingApproval}`;

    const requestId = uuid.v4();
    const visitorsInput: APIt.CreateVisitorInput[] = [];
    const accessLevelsInput: APIt.CreateVisitorAccessLevelInput[] = [];
    const escortsInput: APIt.CreateRequestEscortInput[] = [];
    
    escorts.map(e => {
      const escort: APIt.CreateRequestEscortInput =
        {
          created_by: props.username,
          escort_id: e.username,
          escort_source_system_id: personSourceSystemId!,
          id: uuid.v4(),
          request_id: requestId,
        };
      escortsInput.push(escort);
    });

    vendors.forEach(vendor => {
      const visitorId = uuid.v4();
      const assets: APIt.VisitorAsset[] = [];
      accessLevels.map(al => {
        const visitorAccessLevel: APIt.CreateVisitorAccessLevelInput =
          {
            access_level_id: al.accessLevelId!,
            access_level_name: al.accessLevelName!,
            access_level_source_system_id: accessLevelSourceSystemId!,
            created_by: props.username,
            end_date: al.validThrough,
            id: uuid.v4(),
            permanent_flag: false,
            reason: reasonForAccessRequestInput,
            visitor_id: visitorId,
            site_id: al.site,
            start_date: al.validFrom,
            status_code_id: visitorAccessLevelStatusPendingApprovalId!,
          };
        accessLevelsInput.push(visitorAccessLevel);
        if (vendor.assets && vendor.assets.length > 0) {
          for (let asset of vendor.assets) {
            const assetInput: APIt.VisitorAsset = {
              __typename: 'VisitorAsset',
              approval_status_id: assetApprovalStatusApproved!,
              asset_type_id: asset.asset_type_id,
              created: '',
              created_by: props.username,
              description: asset.description,
              id: '',
              make: asset.make,
              model: asset.model,
              permanent_flag: false,
              person_id: vendor.emailAddress || `${vendor.lastName}, ${vendor.firstName}`,
              serial_num: asset.serial_num,
              site_id: al.site!,
              site_source_system_id: siteSourceSystemId!,
              updated: '',
              updated_by: '',
              visitor_id: visitorId,
            };
            assets.push(assetInput);
          }
        }
      });
      const visitorInput: ICreateVisitorInputWithAssets = {
        assets: assets,
        company: vendor.company,
        created_by: props.username,
        email: vendor.emailAddress,
        first_name: vendor.firstName,
        id: visitorId,
        last_name: vendor.lastName,
        person_id: vendor.emailAddress || `${vendor.lastName}, ${vendor.firstName}`,
        phone_number: vendor.phoneNumber,
        request_id: requestId,
        status_id: visitorStatusPendingApprovalId,
        title: null,
        visitor_pass_assignment_id: null,
        visitor_type_id: visitorTypeVendor!,
      };
      visitorsInput.push(visitorInput);
    });

    const accessLevelRequest: ISubmitAccessLevelRequest = {
      escorts: escortsInput,
      request: {
        created_by: props.username,
        end_date: undefined,
        id: requestId,
        reason: reasonForAccessRequestInput,
        requestor_id: props.username,
        requestor_source_system_id: requestorSourceSystemId,
        site_id: undefined,
        site_source_system_id: undefined,
        start_date: undefined,
        status: undefined,
      },
      accessLevels: accessLevelsInput,
      visitors: visitorsInput,
    };

    try {
      debug(`RequestVendorAccess() submitVendorAccessRequest() accessLevelRequest is ${JSON.stringify(accessLevelRequest)}`);
      await submitAccessRequest(accessLevelRequest, props.disableAutoApproval);
      setShowRequestSubmittedFlashbar(true);
      clearRequest();
      queryClient.fetchQuery({ queryKey: ['pendingVisitorAccessLevels'] });
    } catch(error) {
      console.error(error);
      debug(`RequestVendorAccess() submitVendorAccessRequest() error is ${JSON.stringify(error)}`);
      setErrorMessage((error as any).message ? (error as any).message : JSON.stringify(error));
      setShowRequestSubmissionFailure(true);
    }
    setRequestProcessing(false);
    setShowSubmitRequestAcknowledgementModal(false);
  };

  const clearRequest = async () => {
    setReasonForAccessRequestInput('');
    await Promise.all(
      [
        props.setEscortsCallback([]),
        props.setVendorsCallback([]),
        props.setAccessLevelsCallback([]),
        props.setReasonForAccessRequestCallback('')
      ]);
    setClearDetails(true);
  };

  const detailsCleared = () => {
    setClearDetails(false);
  };

  useEffect(() => {
    setAccessLevels(props.accessLevels);
    setEscorts(props.escorts);
    setVendors(props.vendors);
  }, [props.accessLevels, props.escorts, props.vendors])

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.VendorPassEliminationDate)
      .then((v) => setConfiguredDate(v.value));
  }, []);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(LookupTypes.WelcomeApplicationSettings, WelcomeApplicationSettings.DisableNewVendorDayPassRequests)
      .then((v) => setIsVendorDayPassEnabled(v.value.toLowerCase() !== 'yes')).catch((error) => {
        debug('Error fetching vendor day pass settings: ' + error);
        setIsVendorDayPassEnabled(false);
      });
  }, []);

  const FlashMessageRequestSubmitted = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('request-submitted'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => setShowRequestSubmittedFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageRequestSubmissionFailure = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: `${bundle.getMessage('request-submission-failure')}: ${errorMessage}`,
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => {
              setErrorMessage('');
              setShowRequestSubmissionFailure(false)
            },
          },
        ]
      }
    />
  );

  if (isBundleLoading || !isVendorDayPassEnabled) return <Spinner/>

  return(
    <>
    <Container
      header={
        <Header
          variant='h2'
        >
         {bundle.getMessage('vendor-request')}
        </Header>}
      footer={
        <Box float='right'>
          <SpaceBetween key={'RequestAccessBottomButtonsSpaceBetween'} size='s' direction='horizontal'>
            <Button
              data-testid='RequestVendorAccessClearButton'
              disabled={accessLevels.length == 0 && vendors.length == 0 && !reasonForAccessRequestInput && escorts.length == 0}
              onClick={clearRequest}
            >
              {bundle.getMessage('clear')}
            </Button>
            <Button
              data-testid='RequestVendorAccessReasonSubmitRequestButton'
              disabled={
                accessLevels.length == 0 ||
                vendors.length == 0 ||
                !reasonForAccessRequestInput ||
                escortMissingActiveBadge == null ||
                escortMissingActiveBadge ||
                escorts.length == 0 ||
                escortMissingSiteAccess
              }
              variant='primary'
              loading={requestProcessing}
              onClick={() =>
                {
                  setShowSubmitRequestAcknowledgementModal(true);
                  submitButtonRef.current?.focus();
                }
              }
            >
              {bundle.getMessage('submit-request')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      variant='default'
    >
      {showRequestSubmittedFlashbar && <FlashMessageRequestSubmitted/>}
      {showRequestSubmissionFailure && <FlashMessageRequestSubmissionFailure/>}
      <Escorts
        autoFocus={true}
        cardholdersQuickLinks={props.cardholdersQuickLinks}
        checkForMissingSiteAccess={true}
        darkMode={props.darkMode}
        escorts={escorts}
        username={props.username}
        setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
        setEscortsCallback={props.setEscortsCallback}
        setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
        setEscortMissingSiteAccessCallback={setEscortMissingSiteAccess}
        siteCode={null}
        headerMessage={HeaderMessages.POINTS_OF_CONTACT}
      />
      <Vendors
        vendors={vendors}
        username={props.username}
        setVendorsCallback={props.setVendorsCallback}
        visitorId={props.visitorId}
      />
      <AccessDetails
        accessLevels={accessLevels}
        vendors={vendors}
        clear={clearDetails}
        escorts={escorts}
        detailsClearedCallback={detailsCleared}
        setAccessLevelsCallback={async (accessLevels: IVendorAccessDetailsRecord[]) => {
          await props.setAccessLevelsCallback(accessLevels);
          if (!reasonForAccessRequestInput && accessLevels.length > 0) reasonForAccessRequestRef.current?.focus();
        }}
        username={props.username}
        visitorId={props.visitorId}
      />
      <Container
        header={
          <Header
            variant='h2'
          >
           {bundle.getMessage('justification')}
          </Header>}
        variant='stacked'
      >
        <FormField label={bundle.getMessage('reason-for-access-request')}>
          <Textarea
            data-testid='RequestVendorAccessReasonTextArea'
            onChange={({ detail }) => {
              setReasonForAccessRequestInput(detail.value);
              props.setReasonForAccessRequestCallback(detail.value);
            }}
            placeholder={bundle.getMessage('reason-for-access-request-placeholder')}
            ref={reasonForAccessRequestRef}
            value={reasonForAccessRequestInput}
          />
        </FormField>
      </Container>
    </Container>
    {showSubmitRequestAcknowledgementModal
    &&
    <Modal
      onDismiss={() => setShowSubmitRequestAcknowledgementModal(false)}
      visible={showSubmitRequestAcknowledgementModal}
      closeAriaLabel='Close'
      size='large'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setShowSubmitRequestAcknowledgementModal(false)}>{bundle.getMessage('cancel')}</Button>
            <Button
              disabled={requestProcessing || !isVendorDayPassEnabled}
              loading={requestProcessing}
              onClick={() => isVendorDayPassEnabled && submitVendorAccessRequest()}
              ref={submitButtonRef}
              variant='primary'
            >
              {bundle.getMessage('ok')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('submit-request-information-header')}
    >
      {`${bundle.getMessage('submit-request-information0')} ${configuredDate}, ${bundle.getMessage('submit-request-information1')} - `}<Link target='_blank' href={URLS.GSOSecuritySystemsStandardGlobal}>{bundle.getMessage('gso-security-systems-standard-global')}</Link>{`${bundle.getMessage('submit-request-information2')}`} <Link href={URLS.VendorDayPassDeprecationWiki} target='_blank'>{bundle.getMessage('vendor-day-pass-deprecation-wiki')}</Link>.
    </Modal>
    }
    </>
  );
}