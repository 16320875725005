import { Queue } from "queue-typescript";

export type Period = {
  start_date: Date;
  end_date: Date;
}

export type PeriodWithDays = {
  period: Period;
  daysInWindow: number;
}

export function calculateDaysInWindow(endDate: Date, startDate: Date, msPerDay: number): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.floor((end.setHours(0, 0, 0, 0) - start.setHours(0, 0, 0, 0)) / msPerDay) + 1;
}

export function getRollingWindowStart(periodEndDate: Date, rollingPeriod: number, msPerDay: number): Date {
  return new Date(periodEndDate.getTime() - (rollingPeriod * msPerDay));
}

export function adjustPeriodQueue(
  periodQueue: Queue<PeriodWithDays>, 
  rollingWindowStart: Date, 
  msPerDay: number
): number {
  let totalDaysAdjustment = 0;

  while (periodQueue.length > 0 && 
         (periodQueue.front.period.end_date.getTime() <= rollingWindowStart.getTime() || 
          periodQueue.front.period.start_date.getTime() < rollingWindowStart.getTime())) {
    
    if (periodQueue.front.period.end_date.getTime() <= rollingWindowStart.getTime()) {
      totalDaysAdjustment -= periodQueue.front.daysInWindow;
      periodQueue.dequeue();
    } else {
      const adjustment = Math.ceil(
        (rollingWindowStart.getTime() - periodQueue.front.period.start_date.getTime()) / msPerDay
      );
      totalDaysAdjustment -= adjustment;
      periodQueue.front.daysInWindow -= adjustment;
      periodQueue.front.period.start_date = new Date(rollingWindowStart);
    }
  }

  return totalDaysAdjustment;
}
/**
 * Determines if the total days in the given periods exceed the maximum allowed days
 * within a rolling window.
 * @param periods Array of periods(intervals) to check
 * @param rollingPeriod Number of days in the rolling window
 * @param maxAvailableDays Maximum allowed days within the rolling window
 * @returns true if total days exceed maximum allowed days, false otherwise
 */
export function exceedsAllowedDays(
  periods: Period[],
  rollingPeriod: number,
  maxAvailableDays: number
): boolean {
  const sortedPeriods = [...periods].sort((a, b) =>
    a.end_date.getTime() - b.end_date.getTime()
  );

  let totalDays = 0;
  const periodQueue: Queue<PeriodWithDays> = new Queue<PeriodWithDays>();
  const msPerDay = 1000 * 60 * 60 * 24;
  rollingPeriod -= 1;
  for (const period of sortedPeriods) {
    const rollingWindowStart = getRollingWindowStart(period.end_date, rollingPeriod, msPerDay);
    totalDays += adjustPeriodQueue(periodQueue, rollingWindowStart, msPerDay);

    const periodStart = new Date(Math.max(
      period.start_date.getTime(),
      rollingWindowStart.getTime()
    ));
    const daysInWindow = calculateDaysInWindow(period.end_date, periodStart, msPerDay);

    periodQueue.enqueue({
      period,
      daysInWindow
    });
    totalDays += daysInWindow;
    
    if (totalDays > maxAvailableDays) {
      return true;
    }
  }
  
  return false;
}



export function makeMergedList(accessLevels: Period[], existingIntervals: Period[]): Period[] {
  // Convert access levels to intervals
  const intervals: Period[] = [
    ...accessLevels.map(al => ({
      start_date: new Date(al.start_date!),
      end_date: new Date(al.end_date!)
    })),
    ...existingIntervals
  ];
  intervals.sort((a, b) => a.start_date.getTime() - b.start_date.getTime());

  const mergedIntervals: Period[] = [];

  if (intervals.length === 0) return mergedIntervals;

  let currentInterval = intervals[0];

  for (let i = 1; i < intervals.length; i++) {
    // If current interval overlaps with next interval
    if (intervals[i].start_date <= currentInterval.end_date) {
      // Merge by taking later end date
      currentInterval.end_date = new Date(Math.max(
        currentInterval.end_date.getTime(),
        intervals[i].end_date.getTime()
      ));
    } else {
      // No overlap, add current interval and move to next
      mergedIntervals.push(currentInterval);
      currentInterval = intervals[i];
    }
  }

  // Add the last interval
  mergedIntervals.push(currentInterval);

  return mergedIntervals;
}