import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Input,
  ProgressBar,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import * as APIt from '../../../API';
import VisitorAssetsTablePanel from './Assets/TablePanel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLookupTypeValueId, queryVisitorAssets, queryVisitorAssetTypes } from 'src/components/utils';
import { LookupTypes, VisitorTypes } from 'src/constants/Constants';
import { checkOutVisitor, queryVisitorAccessLevelsForVisitor } from './utils';
import { debug } from 'src/utils/commonUtils';

export interface ICheckOutProps{
  airSite: boolean;
  autoCheckOut?: boolean;
  badgeVerifiedCallback?: Function;
  badgeUnVerifiedCallback?: Function;
  bulkCheckOutCallback?: Function;
  cancelCheckOutCallback: Function | undefined;
  checkOutCallback: Function | undefined;
  siteCode: string;
  submissionProcessing: boolean;
  username: string;
  visitorRequest: APIt.VisitorRequest;
}

export default function CheckOut(props: ICheckOutProps) {
  debug(`CheckOut() props is ${JSON.stringify(props)}`);

  const visitorAccessLevelsQuery = useQuery({
    enabled: (props.visitorRequest !== null),
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: () => setErrorText(''),
    queryKey: ['manageVisitorCheckInVisitorAccessLevels'],
    queryFn: async () => {
      if (!props.visitorRequest) return [];
      return await queryVisitorAccessLevelsForVisitor(props.visitorRequest.visitor_id);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const badgeNotSpecified = (): boolean => {
    debug(`CheckOut() badgeNotSpecified()`);
    return props.visitorRequest.visitor_type == VisitorTypes.Visitor
      ? props.visitorRequest.badge_id == ''
      : props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
        ? props.visitorRequest.vendor_day_pass_badge_num == ''
        : props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee
          ? props.visitorRequest.badge_id == ''
          : false;
  };

  const deriveBadge = (): string => {
    debug(`CheckOut() deriveBadge()`);
    return (props.visitorRequest.visitor_type === VisitorTypes.Visitor || props.visitorRequest.visitor_type === VisitorTypes.EscortedWorker)
    ? props.visitorRequest.badge_id || ''
    : props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
      ? props.visitorRequest.vendor_day_pass_badge_num || ''
      : props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee
        ? props.visitorRequest.badge_id || ''
        : '';
  };

  const areFutureAccessLevels = (): boolean => {
    if (!visitorAccessLevelsQuery.data) {
      return false;
    }
    const now = new Date();
    const futureAccessLevels = visitorAccessLevelsQuery.data.filter((accessLevel: APIt.VisitorAccessLevel) => {
      const endDate = new Date(accessLevel.end_date!);
      return endDate > now;
    });
    return futureAccessLevels.length > 0;
  }

  const [badge, setBadge] = useState<string | null | undefined>(deriveBadge());
  const [badgeVerified, setBadgeVerified] = useState<boolean>(badgeNotSpecified());
  const [checkOutStep, setCheckOutStep] = useState<string | null>(null);
  const [checkOutProgress, setCheckOutProgress] = useState<number>(0);
  const [errorText, setErrorText] = useState<string>('');
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [returning, setReturning] = useState<boolean>(areFutureAccessLevels());

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.CheckOut');

  const queryClient = useQueryClient();

  const visitorAssetsQuery = useQuery({
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: () => setErrorText(''),
    queryKey: [`manageVisitorCheckOutAssets-${props.visitorRequest.visitor_id}`],
    queryFn: async () => {
      const siteSourceSystemId = await getLookupTypeValueId(LookupTypes.SiteSourceSystem, 'PACS');
      const visitorAssets = await queryVisitorAssets(props.visitorRequest.visitor_id, props.siteCode, siteSourceSystemId);
      debug(`CheckOut() visitorAssetsQuery() visitorAssets is ${JSON.stringify(visitorAssets)} for props.visitorRequest.visitor_id ${props.visitorRequest.visitor_id}}`);
      return(visitorAssets);
    },
    retry: 3,
  });

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['manageVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  const checkOutVisitorMutation = useMutation({
    mutationKey: [`manageVisitorCheckOut-${props.visitorRequest.visitor_id}`],
    mutationFn: async () => {
      let badgeId = badge, vendorDayPassBadgeNum = badge;
      if (props.visitorRequest.visitor_type == VisitorTypes.Visitor
      || (props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee)) {
        vendorDayPassBadgeNum = null;
      }
      if (props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor) badgeId = null;
      await checkOutVisitor(
        {
          ...props.visitorRequest,
          badge_id: badgeId,
          vendor_day_pass_badge_num: vendorDayPassBadgeNum,
        },
        returning,
        props.username,
        setCheckOutStep,
        setCheckOutProgress);
    },
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: () => {
      if (!props.autoCheckOut) {
        setBadge(undefined);
        setReturning(false);
        setErrorText('');
      }
      queryClient.invalidateQueries({ queryKey: ['visitorActions'] })
      props.checkOutCallback ? props.checkOutCallback() : null;
      props.bulkCheckOutCallback ? props.bulkCheckOutCallback(props.visitorRequest) : null;
    },
  });

  useEffect(() => {
    debug(`CheckOut() useEffect()[props.visitorRequest, props.siteCode]`);
    visitorAssetsQuery.refetch();
    setBadge(deriveBadge());
    setBadgeVerified(badgeNotSpecified());
  },[props.visitorRequest, props.siteCode]);

  useEffect(() => {
    if (props.autoCheckOut) checkOutVisitorMutation.mutateAsync();
  }, [props.autoCheckOut]);

  useEffect(() => {
    if (badgeVerified) props.badgeVerifiedCallback ? props.badgeVerifiedCallback(props.visitorRequest) : null;
    if (!badgeVerified) props.badgeUnVerifiedCallback ? props.badgeUnVerifiedCallback(props.visitorRequest) : null;
  }, [badgeVerified])

  useEffect(() => {
    setReturning(areFutureAccessLevels());
  }, [visitorAccessLevelsQuery.data]);

  if (isBundleLoading) return <Spinner/>;

  const form =
    <Form
      actions={
        <SpaceBetween direction='horizontal' size='xs'>
          {props.cancelCheckOutCallback
          &&
          <Button
            disabled={checkOutVisitorMutation.isLoading || !props.cancelCheckOutCallback}
            formAction='none'
            variant='normal'
            onClick={() =>
              {
                props.cancelCheckOutCallback ? props.cancelCheckOutCallback() : null;
              }
            }
          >
            {bundle.getMessage('cancel')}
          </Button> }
          {props.cancelCheckOutCallback
          &&
          <Button
            disabled={
              (!badgeVerified && props.visitorRequest.visitor_type != VisitorTypes.Employee)
              ||
              (!badgeVerified && props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee)
              ||
              (!props.checkOutCallback)
            }
            variant='primary'
            loading={checkOutVisitorMutation.isLoading}
          >
            {bundle.getMessage('check-out')}
          </Button>}
        </SpaceBetween>
      }
      errorText={errorText}
      header={
        <>
          {props.visitorRequest.first_name} {props.visitorRequest.last_name}
          {(props.visitorRequest.company != undefined) ? ` (${props.visitorRequest.company})` : ''}
        </>
      }
    >
      <SpaceBetween direction='vertical' size={'s'}>
        {
          (
            props.visitorRequest.visitor_type != VisitorTypes.Employee
            ||
            (props.airSite && props.visitorRequest.visitor_type == VisitorTypes.Employee)
          )
          &&
          <Grid gridDefinition={[{colspan: 6},{colspan: 3},{colspan: 3}]}>
            <div>
              <FormField label={bundle.getMessage('badge-identifier')}>
                <Input
                  autoFocus={!props.bulkCheckOutCallback}
                  disabled={badgeNotSpecified()}
                  invalid={false}
                  onChange={event => { setBadge(event.detail.value); }}
                  onKeyUp={event => { if (event.detail.key == 'Enter') null; }}
                  value={badge || ''}
                />
              </FormField>
            </div>
            <div>
              <FormField label={bundle.getMessage('badge-verified')}>
                <Checkbox
                  checked={badgeVerified}
                  disabled={badgeNotSpecified()}
                  onChange={({detail}) => setBadgeVerified(detail.checked)}
                />
              </FormField>
            </div>
            <div>
              <FormField
                label={
                  props.visitorRequest.visitor_type == VisitorTypes.UnescortedVendor
                    ? bundle.getMessage('vendor-returning')
                    : bundle.getMessage('visitor-returning')}
              >
                <Checkbox
                  checked={returning}
                  onChange={({detail}) => setReturning(detail.checked)}
                />
              </FormField>
            </div>
          </Grid>
        }
        <ExpandableSection
          expanded={expandAssets}
          header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${visitorAssetsQuery.data?.length})` }
          onChange={() => setExpandAssets(!expandAssets)}
        >
          <VisitorAssetsTablePanel
            isTableLoading={visitorAssetsQuery.isFetching || visitorAssetsQuery.isLoading}
            siteCode={props.siteCode}
            username={props.username}
            visitorAssetsQuery={visitorAssetsQuery}
            visitorAssetTypesQuery={visitorAssetTypesQuery}
            visitorId={props.visitorRequest.visitor_id}
            readOnly={true}
          />
        </ExpandableSection>
        {(checkOutStep !== null || props.autoCheckOut)
        &&
        <ProgressBar
          value={checkOutProgress}
          additionalInfo={checkOutStep ? bundle.getMessage(checkOutStep) : null}
          description={bundle.getMessage('check-out-progress')}
          label={bundle.getMessage('progress')}
        />}
      </SpaceBetween>
    </Form>
  ;

  if (props.checkOutCallback) return(
    <form onSubmit={e => {
      e.preventDefault();
      checkOutVisitorMutation.mutate();
    }}>
      {form}
    </form>);

  return(<>{form}</>);
}