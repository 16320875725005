/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const accessControlRequestStatus = /* GraphQL */ `
  mutation AccessControlRequestStatus(
    $input: AccessControlRequestStatusInput!
  ) {
    accessControlRequestStatus(input: $input) {
      error
      message
      requestUUID
      statusCode
    }
  }
`;
export const badgeAccessLevel = /* GraphQL */ `
  mutation BadgeAccessLevel($input: BadgeAccessLevelRequestInput!) {
    badgeAccessLevel(input: $input) {
      statusCode
      error
      message
      requestUUID
    }
  }
`;
export const badgeAssignment = /* GraphQL */ `
  mutation BadgeAssignment($input: BadgeAssignmentInput!) {
    badgeAssignment(input: $input) {
      statusCode
      error
      message
      requestUUID
    }
  }
`;
export const cardholder = /* GraphQL */ `
  mutation Cardholder($input: CardholderRequestInput!) {
    cardholder(input: $input) {
      statusCode
      error
      message
      requestUUID
    }
  }
`;
export const createApproverGroup = /* GraphQL */ `
  mutation CreateApproverGroup($input: CreateApproverGroupInput!) {
    createApproverGroup(input: $input) {
      id
      access_level
      approver_group
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const createCandidateTest = /* GraphQL */ `
  mutation CreateCandidateTest($input: VeritasCreateCandidateTestInput!) {
    createCandidateTest(input: $input) {
      candidateDocumentValue
      candidateTestId
      linkForStartingTest
      apiClientId
    }
  }
`;
export const createDelegation = /* GraphQL */ `
  mutation CreateDelegation($input: CreateDelegationInput!) {
    createDelegation(input: $input) {
      created
      created_by
      delegator_id
      delegator_username
      delegatee_id
      delegatee_username
      delegation_type_id
      delegation_type
      end_date
      justification
      id
      permanent_flag
      start_date
      updated
      updated_by
    }
  }
`;
export const createLookupType = /* GraphQL */ `
  mutation CreateLookupType($input: CreateLookupTypeInput!) {
    createLookupType(input: $input) {
      id
      name
      description
      enabled
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const createLookupTypeValue = /* GraphQL */ `
  mutation CreateLookupTypeValue($input: CreateLookupTypeValueInput!) {
    createLookupTypeValue(input: $input) {
      id
      lookup_type_id
      value
      description
      enabled
      start_date
      end_date
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const createUserAction = /* GraphQL */ `
  mutation CreateUserAction($input: UserActionInput!) {
    createUserAction(input: $input) {
      actionId
      actionName
      parameters
      timestamp
      ttl
      username
    }
  }
`;
export const createUserPrefs = /* GraphQL */ `
  mutation CreateUserPrefs($input: CreateUserPrefsInput!) {
    createUserPrefs(input: $input) {
      dailySummaryEmailTimestamp
      username
      global
      selfService
      management
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      created
      created_by
      end_date
      id
      last_reminder_date
      reason
      requestor_id
      requestor_source_system_id
      site_id
      site_source_system
      site_source_system_id
      start_date
      status
      status_id
      updated
      updated_by
    }
  }
`;
export const createVisitorAssessment = /* GraphQL */ `
  mutation CreateVisitorAssessment($input: CreateVisitorAssessmentInput!) {
    createVisitorAssessment(input: $input) {
      id
      test_link
      integrity_score
      scheduled_date
      test_language_id
      result_received_date
      unique_visitor_id
      document_number
      created
      created_by
      updated
      updated_by
      candidate_test_id
    }
  }
`;
export const createVisitorAccessLevel = /* GraphQL */ `
  mutation CreateVisitorAccessLevel($input: CreateVisitorAccessLevelInput!) {
    createVisitorAccessLevel(input: $input) {
      access_level_id
      access_level_name
      access_level_source_system_id
      company
      created
      created_by
      dates_updated
      email
      end_date
      first_name
      id
      last_name
      last_reminder_date
      person_id
      person_source_system_id
      permanent_flag
      reason
      request_id
      requestor_id
      requestor_source_system_id
      requestuuid
      site_id
      start_date
      status
      status_code_id
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const createVisitorAccessLevelApproval = /* GraphQL */ `
  mutation CreateVisitorAccessLevelApproval(
    $input: CreateVisitorAccessLevelApprovalInput!
  ) {
    createVisitorAccessLevelApproval(input: $input) {
      approver_id
      approver_email_address
      approver_source_system_id
      created
      created_by
      denial_reason_id
      denial_reason
      id
      notes
      status
      status_code_id
      updated
      updated_by
      visitor_access_level_id
    }
  }
`;
export const createVisitorAction = /* GraphQL */ `
  mutation CreateVisitorAction($input: CreateVisitorActionInput!) {
    createVisitorAction(input: $input) {
      id
      visitor_id
      action_type
      action_type_id
      additional_info
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const createRequestEscort = /* GraphQL */ `
  mutation CreateRequestEscort($input: CreateRequestEscortInput!) {
    createRequestEscort(input: $input) {
      id
      request_id
      escort_id
      escort_source_system_id
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const createUniqueVisitor = /* GraphQL */ `
  mutation CreateUniqueVisitor($input: CreateUniqueVisitorInput!) {
    createUniqueVisitor(input: $input) {
      id
      first_name
      last_name
      phone_number
      email_address
      company_name
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const createVisitor = /* GraphQL */ `
  mutation CreateVisitor($input: CreateVisitorInput!) {
    createVisitor(input: $input) {
      badge_id
      badge_requestUUID
      company
      created
      created_by
      dob_month
      dob_day
      email
      first_name
      id
      last_name
      person_id
      person_source_system_id
      phone_number
      request_id
      requestUUID
      status_id
      title
      ttl
      unique_visitor_id
      vendor_day_pass_badge_num
      visitor_type_id
      visitor_pass_assignment_id
      updated
      updated_by
    }
  }
`;
export const createVisitorAsset = /* GraphQL */ `
  mutation CreateVisitorAsset($input: CreateVisitorAssetInput!) {
    createVisitorAsset(input: $input) {
      approval_status
      approval_status_id
      asset_type_id
      asset_type
      created
      created_by
      denial_reason
      denial_reason_id
      description
      end_date
      first_name
      id
      last_name
      make
      model
      notes
      permanent_flag
      person_id
      serial_num
      site_id
      site_source_system_id
      start_date
      sticker_num
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const createVisitorAssetApproval = /* GraphQL */ `
  mutation CreateVisitorAssetApproval(
    $input: CreateVisitorAssetApprovalInput!
  ) {
    createVisitorAssetApproval(input: $input) {
      id
      visitor_asset_id
      approver_id
      approver_email_address
      approver_source_system_id
      status_code_id
      status
      denial_reason_id
      denial_reason
      notes
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const createVisitorPass = /* GraphQL */ `
  mutation CreateVisitorPass($input: CreateVisitorPassInput!) {
    createVisitorPass(input: $input) {
      id
      badge_id
      badge_source_system_id
      visitor_pass_assignment_id
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const createVisitorPassAssignment = /* GraphQL */ `
  mutation CreateVisitorPassAssignment(
    $input: CreateVisitorPassAssignmentInput!
  ) {
    createVisitorPassAssignment(input: $input) {
      id
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const deleteApproverGroup = /* GraphQL */ `
  mutation DeleteApproverGroup($input: DeleteApproverGroupInput!) {
    deleteApproverGroup(input: $input) {
      id
      access_level
      approver_group
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const deleteDelegation = /* GraphQL */ `
  mutation DeleteDelegation($input: DeleteDelegationInput!) {
    deleteDelegation(input: $input) {
      created
      created_by
      delegator_id
      delegator_username
      delegatee_id
      delegatee_username
      delegation_type_id
      delegation_type
      end_date
      justification
      id
      permanent_flag
      start_date
      updated
      updated_by
    }
  }
`;
export const deleteUserPrefs = /* GraphQL */ `
  mutation DeleteUserPrefs($input: DeleteUserPrefsInput!) {
    deleteUserPrefs(input: $input) {
      dailySummaryEmailTimestamp
      username
      global
      selfService
      management
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest($input: DeleteRequestInput!) {
    deleteRequest(input: $input) {
      created
      created_by
      end_date
      id
      last_reminder_date
      reason
      requestor_id
      requestor_source_system_id
      site_id
      site_source_system
      site_source_system_id
      start_date
      status
      status_id
      updated
      updated_by
    }
  }
`;
export const deleteVisitorAccessLevel = /* GraphQL */ `
  mutation DeleteVisitorAccessLevel($input: DeleteVisitorAccessLevelInput!) {
    deleteVisitorAccessLevel(input: $input) {
      access_level_id
      access_level_name
      access_level_source_system_id
      company
      created
      created_by
      dates_updated
      email
      end_date
      first_name
      id
      last_name
      last_reminder_date
      person_id
      person_source_system_id
      permanent_flag
      reason
      request_id
      requestor_id
      requestor_source_system_id
      requestuuid
      site_id
      start_date
      status
      status_code_id
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const deleteVisitorAccessLevelApproval = /* GraphQL */ `
  mutation DeleteVisitorAccessLevelApproval(
    $input: DeleteVisitorAccessLevelApprovalInput!
  ) {
    deleteVisitorAccessLevelApproval(input: $input) {
      approver_id
      approver_email_address
      approver_source_system_id
      created
      created_by
      denial_reason_id
      denial_reason
      id
      notes
      status
      status_code_id
      updated
      updated_by
      visitor_access_level_id
    }
  }
`;
export const deleteRequestEscort = /* GraphQL */ `
  mutation DeleteRequestEscort($input: DeleteRequestEscortInput!) {
    deleteRequestEscort(input: $input) {
      id
      request_id
      escort_id
      escort_source_system_id
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const deleteUniqueVisitor = /* GraphQL */ `
  mutation DeleteUniqueVisitor($input: DeleteUniqueVisitorInput!) {
    deleteUniqueVisitor(input: $input) {
      id
      first_name
      last_name
      phone_number
      email_address
      company_name
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const deleteVisitor = /* GraphQL */ `
  mutation DeleteVisitor($input: DeleteVisitorInput!) {
    deleteVisitor(input: $input) {
      badge_id
      badge_requestUUID
      company
      created
      created_by
      dob_month
      dob_day
      email
      first_name
      id
      last_name
      person_id
      person_source_system_id
      phone_number
      request_id
      requestUUID
      status_id
      title
      ttl
      unique_visitor_id
      vendor_day_pass_badge_num
      visitor_type_id
      visitor_pass_assignment_id
      updated
      updated_by
    }
  }
`;
export const deleteVisitorAsset = /* GraphQL */ `
  mutation DeleteVisitorAsset($input: DeleteVisitorAssetInput!) {
    deleteVisitorAsset(input: $input) {
      approval_status
      approval_status_id
      asset_type_id
      asset_type
      created
      created_by
      denial_reason
      denial_reason_id
      description
      end_date
      first_name
      id
      last_name
      make
      model
      notes
      permanent_flag
      person_id
      serial_num
      site_id
      site_source_system_id
      start_date
      sticker_num
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const deleteVisitorPass = /* GraphQL */ `
  mutation DeleteVisitorPass($input: DeleteVisitorPassInput!) {
    deleteVisitorPass(input: $input) {
      id
      badge_id
      badge_source_system_id
      visitor_pass_assignment_id
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const deleteVisitorPassAssignment = /* GraphQL */ `
  mutation DeleteVisitorPassAssignment(
    $input: DeleteVisitorPassAssignmentInput!
  ) {
    deleteVisitorPassAssignment(input: $input) {
      id
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const getVeritasPass = /* GraphQL */ `
  mutation GetVeritasPass(
    $orgUnitId: String!
    $keyId: String!
    $pass: String!
  ) {
    getVeritasPass(orgUnitId: $orgUnitId, keyId: $keyId, pass: $pass) {
      token
    }
  }
`;
export const markEmployeeDataTTL = /* GraphQL */ `
  mutation MarkEmployeeDataTTL($psid: ID!, $employeeStatus: String) {
    markEmployeeDataTTL(psid: $psid, employeeStatus: $employeeStatus) {
      badge_id
      badge_requestUUID
      company
      created
      created_by
      dob_month
      dob_day
      email
      first_name
      id
      last_name
      person_id
      person_source_system_id
      phone_number
      request_id
      requestUUID
      status_id
      title
      ttl
      unique_visitor_id
      vendor_day_pass_badge_num
      visitor_type_id
      visitor_pass_assignment_id
      updated
      updated_by
    }
  }
`;
export const publishVisitorRequestUpdate = /* GraphQL */ `
  mutation PublishVisitorRequestUpdate(
    $request_id: ID!
    $visitor_id: ID!
    $status_id: String!
  ) {
    publishVisitorRequestUpdate(
      request_id: $request_id
      visitor_id: $visitor_id
      status_id: $status_id
    ) {
      request_id
      visitor_id
      status_id
    }
  }
`;
export const updateApproverGroup = /* GraphQL */ `
  mutation UpdateApproverGroup($input: UpdateApproverGroupInput!) {
    updateApproverGroup(input: $input) {
      id
      access_level
      approver_group
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const updateLookupType = /* GraphQL */ `
  mutation UpdateLookupType($input: UpdateLookupTypeInput!) {
    updateLookupType(input: $input) {
      id
      name
      description
      enabled
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const updateLookupTypeValue = /* GraphQL */ `
  mutation UpdateLookupTypeValue($input: UpdateLookupTypeValueInput!) {
    updateLookupTypeValue(input: $input) {
      id
      lookup_type_id
      value
      description
      enabled
      start_date
      end_date
      created
      updated
      created_by
      updated_by
    }
  }
`;
export const updateUserPrefs = /* GraphQL */ `
  mutation UpdateUserPrefs($input: UpdateUserPrefsInput!) {
    updateUserPrefs(input: $input) {
      dailySummaryEmailTimestamp
      username
      global
      selfService
      management
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      created
      created_by
      end_date
      id
      last_reminder_date
      reason
      requestor_id
      requestor_source_system_id
      site_id
      site_source_system
      site_source_system_id
      start_date
      status
      status_id
      updated
      updated_by
    }
  }
`;
export const updateUniqueVisitor = /* GraphQL */ `
  mutation UpdateUniqueVisitor($input: UpdateUniqueVisitorInput!) {
    updateUniqueVisitor(input: $input) {
      id
      first_name
      last_name
      phone_number
      email_address
      company_name
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const updateVisitorAssessment = /* GraphQL */ `
  mutation UpdateVisitorAssessment($input: UpdateVisitorAssessmentInput!) {
    updateVisitorAssessment(input: $input) {
      id
      test_link
      integrity_score
      scheduled_date
      test_language_id
      result_received_date
      unique_visitor_id
      document_number
      created
      created_by
      updated
      updated_by
      candidate_test_id
    }
  }
`;
export const updateVisitorAccessLevel = /* GraphQL */ `
  mutation UpdateVisitorAccessLevel($input: UpdateVisitorAccessLevelInput!) {
    updateVisitorAccessLevel(input: $input) {
      access_level_id
      access_level_name
      access_level_source_system_id
      company
      created
      created_by
      dates_updated
      email
      end_date
      first_name
      id
      last_name
      last_reminder_date
      person_id
      person_source_system_id
      permanent_flag
      reason
      request_id
      requestor_id
      requestor_source_system_id
      requestuuid
      site_id
      start_date
      status
      status_code_id
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const updateVisitorAccessLevelApproval = /* GraphQL */ `
  mutation UpdateVisitorAccessLevelApproval(
    $input: UpdateVisitorAccessLevelApprovalInput!
  ) {
    updateVisitorAccessLevelApproval(input: $input) {
      approver_id
      approver_email_address
      approver_source_system_id
      created
      created_by
      denial_reason_id
      denial_reason
      id
      notes
      status
      status_code_id
      updated
      updated_by
      visitor_access_level_id
    }
  }
`;
export const updateVisitorAssetApproval = /* GraphQL */ `
  mutation UpdateVisitorAssetApproval(
    $input: UpdateVisitorAssetApprovalInput!
  ) {
    updateVisitorAssetApproval(input: $input) {
      id
      visitor_asset_id
      approver_id
      approver_email_address
      approver_source_system_id
      status_code_id
      status
      denial_reason_id
      denial_reason
      notes
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const updateRequestEscort = /* GraphQL */ `
  mutation UpdateRequestEscort($input: UpdateRequestEscortInput!) {
    updateRequestEscort(input: $input) {
      id
      request_id
      escort_id
      escort_source_system_id
      created
      created_by
      updated
      updated_by
    }
  }
`;
export const updateVisitor = /* GraphQL */ `
  mutation UpdateVisitor($input: UpdateVisitorInput!) {
    updateVisitor(input: $input) {
      badge_id
      badge_requestUUID
      company
      created
      created_by
      dob_month
      dob_day
      email
      first_name
      id
      last_name
      person_id
      person_source_system_id
      phone_number
      request_id
      requestUUID
      status_id
      title
      ttl
      unique_visitor_id
      vendor_day_pass_badge_num
      visitor_type_id
      visitor_pass_assignment_id
      updated
      updated_by
    }
  }
`;
export const updateVisitorAsset = /* GraphQL */ `
  mutation UpdateVisitorAsset($input: UpdateVisitorAssetInput!) {
    updateVisitorAsset(input: $input) {
      approval_status
      approval_status_id
      asset_type_id
      asset_type
      created
      created_by
      denial_reason
      denial_reason_id
      description
      end_date
      first_name
      id
      last_name
      make
      model
      notes
      permanent_flag
      person_id
      serial_num
      site_id
      site_source_system_id
      start_date
      sticker_num
      updated
      updated_by
      visitor_id
      visitor_type
    }
  }
`;
export const updateVisitorPass = /* GraphQL */ `
  mutation UpdateVisitorPass($input: UpdateVisitorPassInput!) {
    updateVisitorPass(input: $input) {
      id
      badge_id
      badge_source_system_id
      visitor_pass_assignment_id
      created
      updated
      created_by
      updated_by
    }
  }
`;
